* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

.r-8 {
  border-radius: 8px;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 132px);
}

.content.complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 132px);
}

.content.complete h1 {
  font-size: 2rem;
  color: #182e77;
  margin: 0 0 20px;
}

.content.complete .failse h1 {
  color: #d9534f;
}

.notification.home h1 {
  margin-bottom: 0;
}

.notification.home h1 + p {
  margin-top: 0;
  color: #999999;
}

.header {
  width: 100%;
  background-color: #fff;
  padding: 15px 10px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.header img {
  max-height: 24px;
}

.content {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.notification {
  text-align: center;
}

.notification h1 {
  text-align: center;
  font-size: 1.75rem;
}

.damage-note .form-control {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: 200px;
  padding: 15px;
  font-size: 1rem;
  font-family: inherit;
}
.damage-note .form-control:focus {
  border: 1px solid #222;
}


.notification ul {
  text-align: left;
}

.content-home {
  text-align: left;
  margin-top: 70px;
}

.content-home + .r-8 {
  margin-top: 20px;
  line-height: 0;
}

.f-width {
  width: 100%;
}

.btn {
  display: inline-block;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  background-color: #182e77;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  min-width: 250px;
}

.btn-primary {
  background-color: #182e77;
}

.btn:disabled {
  background-color: #c0bfbf;
  color: #fff;
  cursor: not-allowed;
}

.select-picture {
  width: 180px;
  height: 180px;
  margin: 100px auto 0;
  border: 2px dashed #182e77;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-picture img {
  max-width: 100px;
  height: auto;
  display: block;
}

.upload-piture-note {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  padding: 0px 15px 10px;
  text-align: center;
  margin: 0 auto 10px;
  width: 100%;
}

.upload-piture-note p {
  margin: 0 0 5px;
}

.upload-piture-note .note {
  font-size: 0.85rem;
  color: #999999;
}

.footer {
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  border-top: 1px solid #e0e0e0;
}

.footer .btn {
  max-width: 500px;
  margin: 0 auto;
  display: block;
}

.hiddenSelect {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  left: -1000px;
  top: -1000px;
  cursor: pointer;
}

#imagePreview {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto 0;
  max-width: 500px;
}

.damage-note {
  max-width: 500px;
  margin: 0 auto;
}
.content-list {
  padding: 15px;
  margin-bottom: auto;
}

#imagePreview .preview-item {
  width: 50%;
  margin: 0 0 20px;
  padding: 0 10px;
  position: relative;
}

#imagePreview .preview-item .remove-button {
  width: 32px;
  height: 32px;
  border: 2px solid #222;
  background-color: #222;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

#imagePreview .preview-item .content-img {
  border-radius: 8px;
  line-height: 0;
  border: 1px solid #e0e0e0;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  position: relative;
}

.warning-message {
  border: 1px solid #d96363;
  border-radius: 4px;
  padding: 15px 10px;
  font-size: 0.75rem;
  width: 100%;
  margin: 0 10px 25px;
  background: #fff;
  color: #d96363;
}

.warning-message p {
  margin: 0;
}

#imagePreview .preview-item .content-img .position {
  position: absolute;
  bottom: 0px;
  background-color: #182e77;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  display: block;
  line-height: 1;
  border-radius: 4px;
}

.selectPoiItem {
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (max-width: 767px){
  .selectPoiItem {
    max-width: 345px;
  }
}

.tc-poi-pie-item {
  stroke: rgb(238, 236, 236);
  stroke-width: 1px;
  outline: none;
  cursor: pointer;
  fill: rgb(255, 255, 255);
}

.tc-poi-pie-item.active {
  fill: #5cb85c;
}

#imagePreview .upload-piture-note {
  width: 100%;
}

body {
  padding: 60px 0 72px;
}

body.loading:before {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  content: "";
  display: block;
  background-image: url(./images/new-car.gif);
  background-repeat: no-repeat;
  background-size: 100px auto;
  background-position: center;
}

.notice-success {
  font-size: 1rem;
  color: #182e77;
  border: 1px solid #182e77;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
}

.notice-failse {
  font-size: 1rem;
  color: #d9534f;
  border: 1px solid #d9534f;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
}